@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../font/Poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../font/Poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../font/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../font/Poppins/Poppins-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../font/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../font/Poppins/Poppins-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../font/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../font/Poppins/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../font/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../font/Poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../font/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../font/Poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../font/Poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../font/Poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../font/Poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../font/Poppins/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
/* ================================================= */
/* ================================================= */
body {
  font-family: "Poppins", sans-serif;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-45 {
  font-size: 45 px !important;
}

.fs-50 {
  font-size: 50px !important;
}

.fs-60 {
  font-size: 60px !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.text_justify {
  text-align: justify !important;
}

.text-white {
  color: #ffffff !important;
}

.text_soft_red {
  color: #D9534F;
}

.text_light {
  color: rgba(67, 89, 113, 0.5) !important;
}

.text_black {
  color: #000000 !important;
}

.text_tidepool {
  color: #0B6E6B !important;
}

.text-left {
  text-align: left !important;
}

// @media (max-width: 991px) {
//   .fs-14 {
//     font-size: 13px !important;
//   }
//   .fs-15 {
//     font-size: 14px !important;
//   }
//   .fs-16 {
//     font-size: 15px !important;
//   }
//   .fs-18 {
//     font-size: 16px !important;
//   }
//   .fs-20 {
//     font-size: 18px !important;
//   }
//   .fs-25 {
//     font-size: 20px !important;
//   }
//   .fs-30 {
//     font-size: 21px !important;
//   }
//   .fs-50 {
//     font-size: 30px !important;
//   }
//   .fs-60 {
//     font-size: 30px !important;
//   }
// }
.lh_1-5 {
  line-height: 1.5;
}

.lh_1-6 {
  line-height: 1.6;
}

.lh_1-7 {
  line-height: 1.7;
}/*# sourceMappingURL=font.css.map */