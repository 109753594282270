$brand_pink_color: #D9534F;
$brand_gray_color: #707070;
$milky_color : #ffffff;
$brand_light_pink_color : #FFFEE2;
$light_gray : #E9E9E9;
$box_shadow_color: #00000029;
$mostly_black_color : #303030;
$red_color : #ff0000;
$fs48: 48px; // $fs36 in max 1600
$fs30: 30px; //$fs22 in max 1600
$fs26: 26px; //$fs17 in max 1600
$fs24: 24px; //$fs16 in max 1600
$fs20: 20px; //$fs13 in max 1600
$fs36: 36px;
$fs22: 22px;
$fs17: 17px;
$fs15: 15px;
$fs16: 16px;
$fs13: 13px;

$h50: 50px; //$h50 in max 1600
$h40: 40px; //$h40 in max 1600
$h62: 62px; //$h40 in max 1600