@import './_Var';
@import './_Toogle_menu';

body {
  font-family: "Poppins", sans-serif;
  // background-color: $light_gray;
}

.bdr_rds_64 {
  border-radius: 64px;
}

.blog_list {
  margin: 25px auto;
  background: $milky_color 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px $box_shadow_color;
  border-radius: 64px;
  opacity: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.blog_list .blog_list_body{
  padding: 15px 50px;
}
.blog_list.atlanta_tech_news{
  background: $brand_light_pink_color  0% 0% no-repeat padding-box;
}
.blog_list.blog_with_thumbnail .blog_list_body{
  padding-left: 15px;
}
.blog_list.blog_with_thumbnail .thumbnail_img{
  height: 100%;
}
.blog_list.blog_with_thumbnail .thumbnail_img img{
  height: 10em;
  width: 200px;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
}

.blog_list a {
  text-decoration: none;
}

.blog_list p {
  font-size: $fs22;
  font-weight: 600;
  letter-spacing: 0px;
  color: $brand_pink_color;
  opacity: 1;
}

.blog_list_details span {
  font-size: $fs17;
  font-weight: normal;
  color: $brand_gray_color;
}

.blog_list .author-name {
  cursor: pointer
}

.blog_list .blog-category {
  cursor: pointer
}
.social-icon {
  padding: 10px 30px;
  font-size: $fs48;
  font-weight: 700;
  color: #252525;
}

.icons{
  margin-right: 10px;
}
.icons img{
  max-width: 20px;
  max-height: 20px;
}
.title_2{
  font-size: $fs48;
  font-weight: 700;
}

.view-icons {
  margin-top: 10px 
}

.fa-list {
  font-size: $fs22;
  color: $brand_pink_color;
  padding: 5px;
}

.fa-table-cells {
  font-size: $fs22;
  color: $brand_pink_color;
  padding: 5px;
}

.fa-angles-left {
  font-size: $fs17;
  color: $brand_pink_color;
  padding: 5px;
}

.grid_view_blogList .blog_list{
  display: block;
  padding: 20px;
  background: $milky_color 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px $box_shadow_color;
  border-radius: 48px;
  opacity: 1;
}
.grid_view_blogList .blog_list.atlanta_tech_news{
  background: $brand_light_pink_color  0% 0% no-repeat padding-box;
}


.grid_view_blogList .blog_list .hdng_lbl{
  font-size: $fs26;
  color: $brand_gray_color;
  font-weight: 600;
}
.btn.more_post_link{
  color: $brand_pink_color;
  font-size: $fs15;
  padding: 10px 0;
  float: right;
  outline: none !important;
  border: 0px !important;
}

.contents p{
  font-size: 20px;
}

.login-div {
  padding: 10% 0;
}

//modal style here



.modal-content{
  border-radius: 20px;
  padding-bottom: 30px;
}
.modal-header{
  border-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.modal-header .modal-title{
  font-size: $fs36;
  color: $brand_pink_color;
  font-weight: 700;
  text-align: center;
}
.modal-body {
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
}
.btn.btn-lg{
  height: 48px;
  font-size: $fs22;
  background-color: $brand_pink_color;
  color: $milky_color;
  background: $brand_pink_color;
  text-transform: uppercase;
  font-weight: 600;
  border: 0;
}
input.form-control,
.form-select{
  height: 36px !important;
  font-size: $fs15 !important;
  letter-spacing: 0px;
  color: $brand_gray_color !important;
  border: 1px solid $brand_gray_color !important;
  border-radius: 30px !important;
}
.form-label{
  font-size: $fs17;
  letter-spacing: 0px;
  color: $brand_gray_color;
}
.color_picker{
  position: absolute;
  right: 0px;
  top: 0;
  height: 36px;
  width: 36px;
  overflow: hidden;
  padding: 0;
  border-radius: 100% !important;
  border: 1px solid #707070;
}
input[type="color" i] {
  border-radius: 50% !important;
}
.settings_pre_code{
  background-color: #ffffff;
  border-radius: 14px;
  padding: 15px;
  border: 1px solid #707070;
}





// =======================================
// ========== admin pages style ==========
// =======================================
.user_body{
  background-color: $light_gray;
  display: flex;
  position: relative;
  width: 100%;
}
.user_side_bar{
  width: 285px;
  background-color: $milky_color;
  // position: absolute;
  min-height: 100vh;
}
.user_conatiner_body{
  // height: 100vh;
  // position: absolute;
  width: 100%;
  width: calc(100% - 285px);
  left: 285px;
  padding: 20px;
  top: 0;
}

.user_side_bar_brand{
  margin: 0 auto;
  display: block;
  max-width: 90px;
}
.user_side_bar .nav_link{
  display: block;
  align-items: center;
  text-decoration: none;
  border-radius: 20px;
}
.user_side_bar .nav_link .nav_link_cntnt{
  width: max-content;
  display: flex;
  align-items: center;
  width: max-content;
  padding: 0px 15px;
  border-radius: 20px;
  height: 35px;
  font-size: $fs22;
  color: $brand_pink_color;
  margin: 15px 25px;
}
.user_side_bar .nav_link:first-child .nav_link_cntnt,
.user_side_bar .nav_link:last-child .nav_link_cntnt{
  margin-top: 50px;
}
// .user_side_bar .nav_link:hover .nav_link_cntnt,
.user_side_bar .nav_link.active .nav_link_cntnt{
  color: $milky_color;
  background-color: $brand_pink_color;
}
.user_side_bar a{
  display: block;
}

.user_side_bar .nav_link .nav_link_icon svg{
  height: 20px;
}
.user_side_bar .nav_link .nav_link_icon{
  fill: $brand_pink_color;
  margin-right: 0px;
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
// .user_side_bar .nav_link:hover .nav_link_icon,
.user_side_bar .nav_link.active .nav_link_icon{
  fill: $milky_color;
}
.ad_page_hdng{
  font-size: $fs36;
  font-weight: 700;
  color: #D9534F;
}

.btn.btn-danger{
  background: #D9534F 0% 0% no-repeat padding-box;
  color: #ffffff;
  padding: 0px 20px;
  border: 0px;
  font-weight: 600;
  border-radius: 31px;
}
.btn.btn-lg{
  height: $h40;
  border-radius: 31px;
  font-size: $fs20;
  font-weight: 600;
}

.btn.btn-md{
  height: $h40;
  border-radius: 31px;
  font-size: $fs16;
  font-weight: 600;
}
.btn.btn-sm{
  height: 25px;
  font-size: $fs13;
}


.form-check-input:checked,
.form-check-input:checked[type="checkbox"] {
  background-color: $brand_pink_color;
  border: $brand_pink_color;
}
.tbl_thumb_img{
  max-width: 95px;
}


table tr th,
table tr td{
  border-color: $brand_gray_color;
  // word-break: break-all;
  word-break: break-all;
}

table tr:last-child td{
  border-bottom: 0px;
}


.btn.btn-danger{
  background: $brand_pink_color 0% 0% no-repeat padding-box;
  color: #ffffff;
  border: 0px;
  font-weight: 600;
  border-radius: 31px;
}

.btn.btn_md{}








@media (min-width: 1400px) {

  .modal-header .modal-title{
      font-size: $fs48;
  }
  input.form-control,
  .form-select{
      height: 49px !important;
      font-size:  $fs20 !important;
  }
  .form-label{
      font-size:  $fs24;
  }
  .blog_list p {
      font-size: $fs30;
  }
  .blog_list_details span {
      font-size: $fs26;
  }
  .container{
    max-width: 1220px;
  }

  .user_side_bar .nav_link .nav_link_cntnt{
    font-size: $fs30;
    height: 43px;
  }
  
  .ad_page_hdng{
    font-size: $fs48;
  }
  .btn.btn-lg{
    height: $h62;
    font-size: $fs30;
  }
  .btn.btn-md{
    height: $h50;
    font-size: $fs24;
  }
  .btn.btn-sm{
    font-size: $fs16;
  }
  
.tbl_thumb_img{
  max-width: 130px;
}

}

.wrapper-class {
  padding: 1rem;
  background: $milky_color;
  border-radius: 20px;
}
.editor-class {
}
.toolbar-class {
  border: 1px solid #ccc;
}
.pagination .page-item .page-link{
  background: transparent;
  color: #404040;
  border: 0px !important;
  outline: none !important;
  box-shadow: none !important;
}
.pagination .page-item.active .page-link{
  color: $brand_pink_color;
}

.rdw-html-editor {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}

/* Loader starts here*/

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #707070;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

/* Loader ends here */