.navbar-toggler{
    border: 0px;
    padding: 0;
    box-shadow: none !important;
    outline: none !important;
}

.search_toggle_btn,
.navbar-toggler .toggle_menu_btn {
    cursor: pointer;
    overflow: hidden;
    width: 45px;
    height: 45px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 7px 0 $box_shadow_color;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.navbar-toggler .toggle_menu_btn  .line-toggle {
    position: absolute;
    display: block;
    width: 35px;
    height: 2px;
    border-radius: 3px;
    border-radius: 5px;
    transition: all .6s;
    background: $brand_pink_color;
} 
.navbar-toggler .toggle_menu_btn .line-toggle:first-child {
    transform: translateY(0) translateX(0) rotate(45deg);
    
    transition-delay: .1s;
}
  
.navbar-toggler .toggle_menu_btn .line-toggle:nth-child(2) {
    transform: translateX(110px);
    transition-delay: 0s;
}
  
.navbar-toggler .toggle_menu_btn .line-toggle:last-child {
    transform: translateY(0) translateX(0) rotate(314deg);
    transition-delay: .1s;
}

.navbar-toggler.collapsed .toggle_menu_btn .line-toggle {
    position: absolute;
    display: block;
    width: 32px;
    height: 2px;
    background: $brand_pink_color;
    border-radius: 5px;
    transition: all .6s;
}
  
.navbar-toggler.collapsed .toggle_menu_btn .line-toggle:first-child {
    transform: translateY(-10px) translateX(-3px);
    width: 25px;
    transition-delay: 0s;
}
.navbar-toggler.collapsed .toggle_menu_btn .line-toggle:nth-child(2) {
    transform: translateX(0px);
    transition-delay: .12s;
}

.navbar-toggler.collapsed .toggle_menu_btn .line-toggle:last-child {
    transform: translateY(11px) translateX(-6px);
    width: 20px;
    transition-delay: 0s;
}
 