@import './_Var';

@media screen and (max-width:1023px) {
    .search_bar{
        width: 100%;
        max-width: 650px;
        width: 100%;
        min-height: 100px;
        background: $milky_color 0% 0% no-repeat padding-box;
        box-shadow: 0px 15px 15px $box_shadow_color;
        border-radius: 0px 0px 20px 20px;
        margin: 0 auto;
        position: absolute;
        display: none;
        border: 0px;
    }
    // .blog_list {
    //     margin: 15px auto;
    //     border-radius: 20px;
    //     display: block;
    // }
    // .blog_list span {
    //     display: block;
    // }
    // .blog_list.blog_with_thumbnail .thumbnail_img img{
    //     width: 100%;
    //     object-fit: cover;
    //     object-position: center;
    //     height: 100px;
    // }
}







@media screen and (max-width:768px) {
    .navbar-collapse{
        border-top: 2px solid $brand_pink_color;
    }
    .grid_view_blogList .blog_list{
        padding: 15px;
        border-radius: 20px;
    }
    .blog_list {
        margin: 15px auto;
        border-radius: 20px;
        display: block;
    }
    .blog_list .thumbnail_img {
        display: block;
        min-height: auto;
        height: 100%;
    }
    .blog_list.blog_with_thumbnail .thumbnail_img img{
        width: 100%;
        object-fit: cover;
        object-position: center;
        height: 200px;
    }
    .blog_list p{
        font-size: 15px;
    }
    .title_2{
        font-size: 35px;
    }
    .modal-header .modal-title{
        font-size: 30px;
    }
    .blog_list .blog_list_body{
        padding: 15px;
    }
} 

@media screen and (max-width:400px) {
    .blog_list.blog_with_thumbnail .thumbnail_img img{
        height: 100px;
    }
    .blog_list_details span{
        font-size: 12px;
    }
    .blog_list_details .icons img{
        max-width: 15px;
    }    
    .title_2{
        font-size: 25px;
    }
    .contents p{
        font-size: 15px;
    }
    .modal-header .modal-title{
        font-size: 25px;
    }
    .blog_list .blog_list_body{
        padding: 15px;
    }
}