@import '.././../assets/css/_Var';

.top_header {
  box-shadow: 0px 0px 15px $box_shadow_color;
}
.top_header a.nav-link,
.top_header a.nav-link.active{
  color: $brand_pink_color;
  margin: 0px 10px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: $fs17;
}

.top_header a.nav-link.active{
  background-color: #D9534F;
  color: #fff;
  border-radius: 20px;
  padding: 4px 15px;
}
    
.top_header a.navbar-brand img {
  height: 70px;
}

.search_link{
  background: transparent !important;
  border: 0px solid $brand_pink_color !important;
}
.search_link img {
  width: 20px;
}
.dropdown-toggle::after{
  display: none;
}

footer p{
  color: $mostly_black_color;
  font-weight: 400;
  font-size: $fs15;
}

footer img{
  height: 100px;
  margin: 20px;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.search_bar_active{
  display: block;  
  max-width: 680px;
  width: 100%;
  min-height: 100px;
  background: $milky_color 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 15px $box_shadow_color;
  border-radius: 0px 0px 20px 20px;
  margin: 0 auto;
  // position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 90px;
}

.btn-brand{
  height: 36px;
  font-size:  $fs15;
  background: $brand_pink_color;
  border-radius: 31px;
  border: 1px solid $brand_pink_color;
  letter-spacing: 0px;
  color: $milky_color;
  font-weight: 700;
  text-transform: uppercase;
}
form .error {
  color: $red_color;
}

@media (min-width: 1400px) {
  .top_header a.navbar-brand img {
    height: 135px;
    }
    .top_header a.nav-link,
    .top_header a.nav-link.active{
      font-size: $fs24;
    }
    
    footer img{
      height: 144px;
    }
    footer p{
      font-size: $fs22;
    }
    .btn-brand{
      height: 49px;
      font-size:  $fs22;
    }
  }